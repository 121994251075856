import React from 'react';
import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import BaseCondition from '../BaseCondition';

class WorkflowsCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  render() {
    return (
      <BaseCondition {...this.props}>
        <div style={{ flexBasis: 200, marginLeft: 8 }}>
          <OperatorSelector
            operators={this.props.condition.filter_condition_type.operators}
            onChange={this.handleChangeOperator}
            value={this.props.condition.operator}
          />
        </div>
      </BaseCondition>
    );
  }
}

WorkflowsCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default WorkflowsCondition;
