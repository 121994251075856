import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import Icon from '../shared/Icon';

import { smallStyles, defaultStyles } from '../../styles/select_styles';

const NoComp = () => {
  return null;
};

const Option = (props) => {
  // User / InstallationCompany
  if (props.data.display_name) {
    const company = props.data.responsible_options_type === 'installation_company';

    return (
      <components.Option {...props}>
        <span className="fullname_with_avatar">
          <span className={`avatar-wrapper avatar-wrapper-mini avatar-link-with-fullname ${company ? 'avatar-wrapper-square' : ''}`}>
            <img src={props.data.avatar.thumb.url} alt={props.data.display_name} className={`avatar avatar-mini ${company ? 'avatar-square' : ''}`} />
          </span>
          {props.data.display_name}
        </span>
        {props.data.status_unavailable ? (
          <span
            className="selector-user-unavailable"
            title={`${props.data.status_emoji} ${props.data.status_message}`}>
            nicht verfügbar
          </span>
        ) : ''}
      </components.Option>
    );
  }

  // Rolle
  return (
    <components.Option {...props}>
      <Icon name="circle" color={props.data.color} /> {props.data.name}
      {!props.data.project_role_id ? '' : (
        <span style={{ fontSize: 11, fontWeight: 500, opacity: 0.7 }}> PR</span>
      )}
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
};

const SingleValue = (props) => {
  // User / InstallationCompany
  if (props.data.display_name) {
    const company = props.data.responsible_options_type === 'installation_company';

    return (
      <components.SingleValue {...props}>
        <span className="fullname_with_avatar">
          <span className={`avatar-wrapper avatar-wrapper-mini avatar-link-with-fullname ${company ? 'avatar-wrapper-square' : ''}`}>
            <img src={props.data.avatar.thumb.url} alt={props.data.display_name} className={`avatar avatar-mini ${company ? 'avatar-square' : ''}`} />
          </span>
          {props.data.display_name}
        </span>
      </components.SingleValue>
    );
  }

  // Rolle
  return (
    <components.SingleValue {...props}>
      <Icon name="circle" color={props.data.color} /> {props.data.name}
      {!props.data.project_role_id ? '' : (
        <span style={{ fontSize: 11, fontWeight: 500, opacity: 0.7 }}> PR</span>
      )}
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  data: PropTypes.object,
};

class ResponsibleSelector extends Component {
  getGroupedOptions({ suggestions = [], users = [], installation_companies = [], roles = [] }) {
    const ret = [];

    if (suggestions.length > 0) {
      ret.push({
        label: 'Vorschläge',
        options: suggestions.filter(u => !this.props.excludeUserIds.includes(u.id)),
      });
    }

    if (roles.length > 0) {
      ret.push({
        label: 'Rollen',
        options: roles,
      });
    }

    // filter out users whose ID is in exclude list
    // filter out users who are already in suggestions list
    if (users.length > 0) {
      ret.push({
        label: 'Benutzer',
        options: users
          .filter(u => !this.props.excludeUserIds.includes(u.id))
          .filter(u => !suggestions.find(u2 => u.id === u2.id)),
      });
    }

    if (installation_companies.length > 0) {
      ret.push({
        label: 'Fachpartner',
        options: installation_companies,
      });
    }

    return ret;
  }

  getOptions = (searchTerm) => {
    const url = `${this.props.optionsUrl}?q=${encodeURIComponent(searchTerm)}&suggestion_context=${this.props.suggestionContext || 'UserSelector'}&show_roles=${this.props.showRoles ? 1 : 0}&without_company_users=1`;

    return fetch(url, {
      credentials: 'include',
    }).then((response) => {
      return response.json();
    }).then((options) => {
      return this.getGroupedOptions(options);
    });
  };

  render() {
    return (
      <AsyncSelect
        components={{
          Option,
          SingleValue,
          DropdownIndicator:
            this.props.hideDropdownIndicator ? NoComp : components.DropdownIndicator,
          IndicatorSeparator:
            this.props.hideDropdownIndicator ? NoComp : components.IndicatorSeparator,
        }}
        isDisabled={this.props.isDisabled}
        defaultValue={this.props.value}
        isClearable
        getValue={options => options.responsible_param}
        openMenuOnFocus
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        backspaceRemovesValue={false}
        blurInputOnSelect
        menuPlacement="auto"
        onChange={this.props.onChange}
        handleInputChange={this.handleInputChange}
        loadOptions={this.getOptions}
        defaultOptions
        styles={this.props.size === 'small' ? smallStyles : defaultStyles}
        classNames={this.props.size === 'small' && 'form-control-sm'}
        placeholder={this.props.placeholder}
        loadingPlaceholder="Laden..."
        noOptionsMessage={() => 'Keine Ergebnisse'}
        loadingMessage={() => 'Laden...'}
      />
    );
  }
}

ResponsibleSelector.propTypes = {
  onChange: PropTypes.func,
  excludeUserIds: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  hideDropdownIndicator: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
  suggestionContext: PropTypes.string,
  showRoles: PropTypes.bool,
};

ResponsibleSelector.defaultProps = {
  excludeUserIds: [],
  placeholder: 'Verantwortlicher',
  suggestionContext: 'ResponsibleSelector',
  showRoles: true,
};

export default ResponsibleSelector;
