import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';
import Button from '../shared/Button';
import FullnameWithAvatar from '../shared/FullNameWithAvatar';
import ResponsibleSelector from '../shared/ResponsibleSelector';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import { updateAsync } from '../../helpers/rails_helper';

class TodoResponsible extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todo: props.todo,
      changedTodo: props.todo,
      responsible: props.todo.role || props.todo.responsible,
      editing: false,
      saving: false,
    };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'TodoChangesChannel',
      todo_id: this.state.todo.id,
    }, ({ action, todo }) => {
      if (action === 'update') {
        this.setState({ todo, editing: false, saving: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
    }
  }

  handleSaveClick = (e) => {
    e.preventDefault();
    this.setState({ saving: true });

    updateAsync(`/todos/${this.state.todo.id}/set_responsible`, {
      todo: {
        responsible_gid: this.state.responsible?.gid || null,
      },
    }, 'PATCH').then((result) => {
      this.setState({
        editing: false,
        saving: false,
        responsible: result.todo.role || result.todo.responsible,
      });
    });
  };

  toggleEdit = (e) => {
    e.preventDefault();
    this.setState({ editing: !this.state.editing });
  };

  renderEditButton() {
    if (this.props.mayEdit) {
      return (
        <a href="" className="todo-toggle-responsible-link" onClick={this.toggleEdit}>
          <Icon name="cog" />
        </a>
      );
    }

    return null;
  }

  renderResponsible() {
    if (this.state.responsible) {
      switch (this.state.responsible.responsible_options_type) {
        case 'role':
          return (
            <Fragment>
              <Icon name="circle" color={this.state.responsible.color} /> {this.state.responsible.name}
            </Fragment>
          );
        case 'user':
        case 'installation_company':
          return (
            <FullnameWithAvatar model={this.state.responsible} />
          );
        default:
          return null;
      }
    }

    return 'Kein Verantwortlicher eingetragen';
  }

  renderForm() {
    return (
      <Fragment>
        <div style={{ marginBottom: 10 }}>
          <ResponsibleSelector
            value={this.state.responsible}
            optionsUrl={this.props.optionsUrl}
            onChange={(responsible) => this.setState({ responsible })}
            suggestionContext={`responsible-todo-template-id-${this.props.todo.todo_template_id}`}
          />
        </div>
        <p>
          <Button loading={this.state.saving} className="btn btn-sm btn-primary" onClick={this.handleSaveClick}>
            Speichern
          </Button>{' '}
          <button className="btn btn-sm btn-link" onClick={this.toggleEdit}>
            abbrechen
          </button>
        </p>
      </Fragment>
    );
  }

  render() {
    return (
      <div>
        <div className="todo-side-headline">Verantwortlich
          {this.renderEditButton()}
        </div>
        <div>
          {this.state.editing ? (
            this.renderForm()
          ) : (
            <p id="todo-responsible" className="text-muted">
              {this.renderResponsible()}
            </p>
          )}
        </div>
        <hr />
      </div>
    );
  }
}

TodoResponsible.propTypes = {
  todo: PropTypes.object.isRequired,
  mayEdit: PropTypes.bool,
  optionsUrl: PropTypes.string.isRequired,
};

export default TodoResponsible;
