/* global document, google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  POLYGON_FILL_COLOR: '#0066CC',
  POLYGON_FILL_OPACITY: 0.2,
  POLYGON_FILL_COLOR_HOVER: '#0066CC',
  POLYGON_FILL_OPACITY_HOVER: 0.4,
  POLYGON_STROKE_COLOR: '#0066CC',
};

const polygonDefaultStyles = {
  strokeColor: styles.POLYGON_STROKE_COLOR,
  strokeOpacity: 0.8,
  strokeWeight: 3,
  fillColor: styles.POLYGON_FILL_COLOR,
  fillOpacity: styles.POLYGON_FILL_OPACITY,
};

class AreaMap extends Component {
  componentDidMount() {
    if (typeof google !== 'undefined') {
      this.initAreaMap();
    } else {
      document.addEventListener('google_maps_api_loaded', () => {
        this.initAreaMap();
      }, false);
    }
  }

  addAreaByMapPolygon = (mapPolygon) => {
    const newArea = {
      mapPolygon,
      id: (new Date()).getMilliseconds(),
    };

    this.registerMapEvents(newArea);
    this.props.areaAdded(newArea);
  }

  deleteNode = (area, mev) => {
    if (mev.vertex != null) {
      if (area.mapPolygon.getPath().length <= 3) {
        this.props.areaRemoved(area);
      } else {
        area.mapPolygon.getPath().removeAt(mev.vertex);
        this.props.areaChanged(area);
      }
    }
  }

  registerMapEvents = (area) => {
    google.maps.event.addListener(area.mapPolygon, 'rightclick', (mev) => {
      this.deleteNode(area, mev);
    });

    google.maps.event.addListener(area.mapPolygon.getPath(), 'insert_at', () => {
      this.props.areaChanged(area);
    });

    google.maps.event.addListener(area.mapPolygon.getPath(), 'remove_at', () => {
      this.props.areaChanged(area);
    });

    google.maps.event.addListener(area.mapPolygon.getPath(), 'set_at', () => {
      this.props.areaChanged(area);
    });
  }

  initAreaMap = () => {
    const map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 51, lng: 10.2 },
      zoom: 6,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
    });

    const bounds = new google.maps.LatLngBounds();

    if (this.props.editable) {
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
          ],
        },
        polygonOptions: {
          ...polygonDefaultStyles,
          editable: true,
        },
        map,
      });

      drawingManager.setMap(map);

      google.maps.event.addDomListener(drawingManager, 'polygoncomplete', (mapPolygon) => {
        this.addAreaByMapPolygon(mapPolygon);
      });
    }

    this.props.initial_areas.forEach((area) => {
      const points = [];

      area.polygon.forEach((point, i) => {
        points[i] = new google.maps.LatLng(point.lat, point.lon);
        bounds.extend(points[i]);
      });

      const mapPolygon = new google.maps.Polygon({
        paths: points,
        ...polygonDefaultStyles,
        editable: this.props.editable,
        map,
      });

      const newArea = {
        persisted: true,
        id: area.id,
        product_id: area.area_product?.product_id,
        area_product_id: area.area_product?.id,
        mapPolygon,
      };

      if (this.props.editable) {
        this.registerMapEvents(newArea);
        this.props.areaAdded(newArea);
      }
    });

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }


    this.props.postal_addresses.forEach((postal_address) => {
      if (postal_address.lat && postal_address.lon) {
        const infowindow = new google.maps.InfoWindow({
          content: `
            <div>
              <p>${postal_address.address_to_show}</p>
            </div>
          `,
        });

        const marker = new google.maps.Marker({
          id: postal_address.id,
          position: { lat: postal_address.lat, lng: postal_address.lon },
          map,
        });

        marker.addListener('click', () => {
          infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });

        // markers.push(marker);
      }
    });
  };

  render() {
    return (
      <div id="map" style={this.props.containerStyle} />
    );
  }
}

AreaMap.propTypes = {
  editable: PropTypes.bool,
  areaChanged: PropTypes.func,
  areaAdded: PropTypes.func,
  areaRemoved: PropTypes.func,
  initial_areas: PropTypes.array.isRequired,
  postal_addresses: PropTypes.array.isRequired,
  containerStyle: PropTypes.object.isRequired,
};

AreaMap.defaultProps = {
  initial_areas: [],
  postal_addresses: [],
  containerStyle: {
    height: 600,
  },
};

export default AreaMap;

export {
  styles,
  polygonDefaultStyles,
};
