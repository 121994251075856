import NullCondition from './Conditions/NullCondition';

import DueCondition from './Conditions/Projects/DueCondition';
import ArchivedCondition from './Conditions/Projects/ArchivedCondition';
import AnonymizedCondition from './Conditions/Projects/AnonymizedCondition';
import TagCondition from './Conditions/Projects/TagCondition';
import StateCondition from './Conditions/Projects/StateCondition';
import TypeCondition from './Conditions/Projects/TypeCondition';
import ProjectRoleCondition from './Conditions/Projects/ProjectRoleCondition';
import WorkflowCondition from './Conditions/Projects/WorkflowCondition';
import WorkflowsCondition from './Conditions/Projects/WorkflowsCondition';
import DateDataPointCondition from './Conditions/Projects/DateDataPointCondition';
import DateTimeDataPointCondition from './Conditions/Projects/DateTimeDataPointCondition';
import StringDataPointCondition from './Conditions/Projects/StringDataPointCondition';
import StringChoiceDataPointCondition from './Conditions/Projects/StringChoiceDataPointCondition';
import MultiStringChoiceDataPointCondition from './Conditions/Projects/MultiStringChoiceDataPointCondition';
import MoneyDataPointCondition from './Conditions/Projects/MoneyDataPointCondition';
import BooleanDataPointCondition from './Conditions/Projects/BooleanDataPointCondition';
import ListDataPointCondition from './Conditions/Projects/ListDataPointCondition';
import DocumentDataPointCondition from './Conditions/Projects/DocumentDataPointCondition';
import DataPointUpdatedCondition from './Conditions/Projects/DataPointUpdatedCondition';
import DataPointConfirmedCondition from './Conditions/Projects/DataPointConfirmedCondition';
import DataPointTimeDiffCondition from './Conditions/Projects/DataPointTimeDiffCondition';
import PhoneNumberCondition from './Conditions/Projects/PhoneNumberCondition';
import ZipCodeCondition from './Conditions/Projects/ZipCodeCondition';
import RequestedProductCondition from './Conditions/Projects/RequestedProductCondition';
import ProjectSegmentCondition from './Conditions/Projects/ProjectSegmentCondition';

import CreatedAtCondition from './Conditions/Offers/CreatedAtCondition';
import TrashedCondition from './Conditions/Offers/TrashedCondition';
import OrderedCondition from './Conditions/Offers/OrderedCondition';
import DraftCondition from './Conditions/Offers/DraftCondition';
import OfferTypeCondition from './Conditions/Offers/TypeCondition';
import CanceledCondition from './Conditions/Offers/CanceledCondition';
import ConfirmedCondition from './Conditions/Offers/ConfirmedCondition';
import TemplateCondition from './Conditions/Workflows/TemplateCondition';
import ActiveTodosCondition from './Conditions/Workflows/ActiveTodosCondition';

import OfferDateCondition from './Conditions/ProductDependencies/OfferDateCondition';

import ProductCategoryCondition from './Conditions/OfferPositions/ProductCategoryCondition';

import CollaborationStatusCondition from './Conditions/InstallationCompanies/CollaborationStatusCondition';
import LegalStatusCondition from './Conditions/InstallationCompanies/LegalStatusCondition';
import CraftCondition from './Conditions/InstallationCompanies/CraftCondition';

const ConditionComps = {
  'NullCondition': NullCondition,
  'Conditions::Projects::DueCondition': DueCondition,
  'Conditions::Projects::ArchivedCondition': ArchivedCondition,
  'Conditions::Projects::AnonymizedCondition': AnonymizedCondition,
  'Conditions::Projects::TagCondition': TagCondition,
  'Conditions::Projects::StateCondition': StateCondition,
  'Conditions::Projects::TypeCondition': TypeCondition,
  'Conditions::Projects::ProjectRoleCondition': ProjectRoleCondition,
  'Conditions::Projects::WorkflowCondition': WorkflowCondition,
  'Conditions::Projects::WorkflowsCondition': WorkflowsCondition,
  'Conditions::Projects::DateDataPointCondition': DateDataPointCondition,
  'Conditions::Projects::DateTimeDataPointCondition': DateTimeDataPointCondition,
  'Conditions::Projects::StringDataPointCondition': StringDataPointCondition,
  'Conditions::Projects::StringChoiceDataPointCondition': StringChoiceDataPointCondition,
  'Conditions::Projects::MultiStringChoiceDataPointCondition': MultiStringChoiceDataPointCondition,
  'Conditions::Projects::MoneyDataPointCondition': MoneyDataPointCondition,
  'Conditions::Projects::BooleanDataPointCondition': BooleanDataPointCondition,
  'Conditions::Projects::ListDataPointCondition': ListDataPointCondition,
  'Conditions::Projects::DocumentDataPointCondition': DocumentDataPointCondition,
  'Conditions::Projects::DataPointUpdatedCondition': DataPointUpdatedCondition,
  'Conditions::Projects::DataPointConfirmedCondition': DataPointConfirmedCondition,
  'Conditions::Projects::DataPointTimeDiffCondition': DataPointTimeDiffCondition,
  'Conditions::Projects::PhoneNumberCondition': PhoneNumberCondition,
  'Conditions::Projects::ZipCodeCondition': ZipCodeCondition,
  'Conditions::Projects::ObjectPostalAddressCondition': ZipCodeCondition, // reuse component
  'Conditions::Projects::RequestedProductCondition': RequestedProductCondition,
  'Conditions::Projects::ProjectSegmentCondition': ProjectSegmentCondition,

  'Conditions::Offers::CreatedAtCondition': CreatedAtCondition,
  'Conditions::Offers::TrashedCondition': TrashedCondition,
  'Conditions::Offers::OrderedCondition': OrderedCondition,
  'Conditions::Offers::DraftCondition': DraftCondition,
  'Conditions::Offers::TypeCondition': OfferTypeCondition,
  'Conditions::Offers::CanceledCondition': CanceledCondition,
  'Conditions::Offers::ConfirmedCondition': ConfirmedCondition,
  'Conditions::Workflows::TemplateCondition': TemplateCondition,
  'Conditions::Workflows::ActiveTodosCondition': ActiveTodosCondition,

  'Conditions::ProductDependencies::OfferDateCondition': OfferDateCondition,

  'Conditions::OfferPositions::ProductCategoryCondition': ProductCategoryCondition,
  'Conditions::InstallationCompanies::CollaborationStatusCondition': CollaborationStatusCondition,
  'Conditions::InstallationCompanies::LegalStatusCondition': LegalStatusCondition,
  'Conditions::InstallationCompanies::CraftCondition': CraftCondition,
};

export default ConditionComps;
