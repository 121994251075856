import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import { debounce } from 'lodash';

export default class extends Controller {
  static outlets = ['body'];

  static targets = [
    'craft',
    'providedByCustomer',
    'startDate',
    'endDate',
    'searchType',
    'searchDistance',
    'searchInstallationCompany',
    'installationCompanyId',
    'appointmentId',
    'appointmentTypeId',
    'submit',
    'loadingSpinner',
  ];

  static values = {
    capacitiesPath: String,
    postalAddressId: Number,
    projectId: Number,
  };

  initialize() {
    this.searchInstallationCapacities = debounce(this.searchInstallationCapacities, 200).bind(this);
  }

  connect() {
    this.toggleAppointmentTypes();
    this.toggleSubmit();
    this.searchInstallationCapacities();
  }

  craftToggled() {
    this.toggleSubmit();

    const selectedCrafts = this.craftTargets.filter(checkbox => checkbox.checked);

    if (selectedCrafts.length === 0) {
      this.providedByCustomerTarget.disabled = true;
      this.providedByCustomerTarget.checked = false;
      this.#disableSearchFields(true);
      this.toggleAppointmentTypes();
      return;
    }

    const allCraftsProvidableByCustomer = selectedCrafts.every(craft => craft.dataset.providableByCustomer);
    this.providedByCustomerTarget.disabled = !allCraftsProvidableByCustomer;

    if (!allCraftsProvidableByCustomer) {
      this.providedByCustomerTarget.checked = false;
      // this.#disableSearchFields(true);
      this.toggleAppointmentTypes();
    }

    this.#disableSearchFields(false);
    this.searchInstallationCapacities();
  }

  providedByCustomerToggled() {
    this.#disableSearchFields(this.providedByCustomerTarget.checked);
  }

  #disableSearchFields(bool) {
    this.startDateTarget.disabled = bool;
    this.endDateTarget.disabled = bool;
    this.searchTypeTargets.forEach(t => {
      t.disabled = bool;
    });
    this.searchDistanceTarget.disabled = bool;
    this.toggleAppointmentTypes();
    this.searchInstallationCompanyTarget.disabled = bool;
  }

  selectSearchDistance() {
    if (this.providedByCustomerTarget.checked) return;
    const searchTypeDistanceTarget = this.searchTypeTargets.find(searchTypeTarget => searchTypeTarget.value === 'distance');
    searchTypeDistanceTarget.checked = true;
    this.searchDistanceTarget.disabled = false;
    this.searchDistanceTarget.focus();
    this.searchInstallationCompanyTarget.disabled = true;
    this.searchInstallationCapacities();
  }

  selectInstallationCompany() {
    if (this.providedByCustomerTarget.checked) return;
    const searchTypeInstallationCompanyTarget = this.searchTypeTargets.find(searchTypeTarget => searchTypeTarget.value === 'installation_company');
    searchTypeInstallationCompanyTarget.checked = true;
    this.searchInstallationCompanyTarget.disabled = false;
    this.searchInstallationCompanyTarget.focus();
    this.searchDistanceTarget.disabled = true;
  }

  searchInstallationCapacities() {
    this.installationCompanyIdTarget.value = '';
    this.appointmentIdTarget.value = '';

    const selectedSearchType = this.searchTypeTargets.find(t => t.checked);
    if (!selectedSearchType) return;

    const params = new URLSearchParams();

    this.craftTargets.forEach((craft) => {
      if (craft.checked) params.append('craft_ids[]', craft.value);
    });
    params.append('provided_by_customer', this.providedByCustomerTarget.checked);
    params.append('project_id', this.projectIdValue);
    params.append('postal_address_id', this.postalAddressIdValue);
    params.append('by_crafts', '1');
    params.append('start_date', this.startDateTarget.value);
    params.append('end_date', this.endDateTarget.value);

    this.loadingSpinnerTarget.classList.remove('d-none');

    this.searchDistanceTarget.disabled = selectedSearchType.value !== 'distance';
    this.searchInstallationCompanyTarget.disabled = selectedSearchType.value !== 'installation_company';
    params.append('search_type', selectedSearchType.value);
    if (selectedSearchType.value === 'distance') params.append('search_distance', this.searchDistanceTarget.value);
    if (selectedSearchType.value === 'installation_company') params.append('search_installation_company', this.searchInstallationCompanyTarget.value);

    get(`${this.capacitiesPathValue}?${params.toString()}`, {
      responseKind: 'turbo-stream',
    }).then(_response => {
      this.loadingSpinnerTarget.classList.add('d-none');
      this.toggleAppointmentTypes();
      setTimeout(() => {
        this.bodyOutlets.forEach(bodyOutlet => bodyOutlet.connect());
      }, 500);
    });
  }

  setInstallationIds(event) {
    let changed = false;

    const newInstallationCompanyId = event.target.dataset.installationCompanyId || '';
    if (this.installationCompanyIdTarget.value !== newInstallationCompanyId) {
      this.installationCompanyIdTarget.value = newInstallationCompanyId;
      changed = true;
    }

    const newAppointmentId = event.target.dataset.appointmentId || '';
    if (this.appointmentIdTarget.value !== newAppointmentId) {
      this.appointmentIdTarget.value = newAppointmentId || '';
      changed = true;
    }

    if (!changed && event.target.checked) {
      event.target.checked = false;
      this.installationCompanyIdTarget.value = '';
      this.appointmentIdTarget.value = '';
    }

    this.toggleAppointmentTypes();
    this.toggleSubmit();
  }

  toggleAppointmentTypes() {
    if (this.appointmentIdTarget.value) {
      const selectedAppointmentType = this.appointmentTypeIdTargets.find(appointmentType => {
        const supportedCraftIds = JSON.parse(appointmentType.dataset.supportedCraftIds);

        const selectedCraftIds = this.craftTargets.reduce((accumulator, craftBox) => {
          if (craftBox.checked) {
            accumulator.push(parseInt(craftBox.value, 10));
          }
          return accumulator;
        }, []);

        if (selectedCraftIds.length) {
          const supported = selectedCraftIds.every(selectedCraftId => supportedCraftIds.includes(selectedCraftId));
          if (supported && !this.providedByCustomerTarget.checked) {
            appointmentType.disabled = false;
          } else {
            appointmentType.disabled = true;
            appointmentType.checked = false;
          }
        } else {
          appointmentType.disabled = true;
          appointmentType.checked = false;
        }

        return appointmentType.checked;
      });
      if (!selectedAppointmentType) {
        const firstAppointmentTypeBox = this.appointmentTypeIdTargets.find(appointmentTypeBox => {
          return !appointmentTypeBox.disabled;
        });
        if (firstAppointmentTypeBox) firstAppointmentTypeBox.checked = !this.appointmentIdTarget.value;
      }
    } else {
      this.appointmentTypeIdTargets.forEach(t => {
        t.disabled = true;
        t.checked = false;
      });
    }
  }

  appointmentTypeToggled() {
    this.toggleSubmit();
  }

  toggleSubmit() {
    if (!this.craftTargets.some(craftBox => craftBox.checked)) {
      this.submitTarget.disabled = true;
      return;
    }

    if (this.appointmentIdTarget.value && !this.appointmentTypeIdTargets.some(appointmentTypeBox => appointmentTypeBox.checked)) {
      this.submitTarget.disabled = true;
      return;
    }

    this.submitTarget.disabled = false;
  }
}
