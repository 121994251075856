import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// Rendert die via 'comp' übergeben Komponenten mit allen Props
// und fügt zusätzlich ein hidden form field ein, das bei Änderung
// mit dem Wert befüllt wird
//
// Dies ist nützlich, falls man eine React-Komponenten als Teil eines
// normalen HTML-Formulars verwenden möchte
class HiddenInputWrapper extends Component {
  constructor(props) {
    super(props);

    let hiddenFieldValue = props.value;

    if (props.transformValue && props.value) {
      hiddenFieldValue = props.transformValue(props.value);
    }

    this.state = {
      hiddenFieldValue,
      value: props.value,
    };
  }

  handleChange = (rawValue, optionalValues) => {
    let hiddenFieldValue = rawValue;

    if (this.props.transformValue) {
      hiddenFieldValue = this.props.transformValue(rawValue);
    }

    this.setState({ hiddenFieldValue });

    if (this.props.onChange) {
      this.props.onChange(rawValue, optionalValues);
    }
  };

  render() {
    const { comp, name, ...rest } = this.props;
    const Comp = comp;
    return (
      <Fragment>
        <input
          type="hidden"
          name={name}
          value={this.state.hiddenFieldValue || ''}
        />
        <Comp {...rest} onChange={this.handleChange} value={this.state.value} />
      </Fragment>
    );
  }
}

HiddenInputWrapper.propTypes = {
  comp: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  transformValue: PropTypes.func,
};

export default HiddenInputWrapper;
