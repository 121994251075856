import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import Tooltip from '../shared/Tooltip';
import Icon from '../shared/Icon';
import OrderRequest from './OrderRequest';

class NewOrderRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderRequests: (props.orderRequests || []).map((o, i) => {
        return Object.assign({}, o, { key: i });
      }),
      addDependencies: true,
    };

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.addDependentPositions = this.addDependentPositions.bind(this);
  }

  handleRemove(index) {
    this.setState({
      orderRequests: this.state.orderRequests.filter((o, i) => (i !== index)),
    });
  }

  handleAddClick(e) {
    e.preventDefault();

    this.setState({
      orderRequests: [...this.state.orderRequests, {
        key: (new Date()).getTime(),
        amount: 1,
      }],
    });
  }

  handleToggleAddDependentPositions() {
    this.setState({ addDependencies: !this.state.addDependencies });
  }

  addDependentPositions(productIdsWithAmount, isProductPackage, parentsIndex, parentsAmount = 1) {
    if (!this.state.addDependencies) {
      return;
    }

    const additionalOrderRequests = productIdsWithAmount.map((productIdWithAmount) => {
      return {
        // (new Date()).getTime() not sufficient here. I've got duplicate keys in development.
        key: Math.floor(Math.random() * (1000000000 - 9)) + 10,
        amount: productIdWithAmount.amount * parentsAmount,
        product_id: productIdWithAmount.id,
      };
    });

    const orderRequestsClone = cloneDeep(this.state.orderRequests);

    if (isProductPackage) {
      orderRequestsClone.splice(parentsIndex, 1);
    }

    this.setState({ orderRequests: [...orderRequestsClone, ...additionalOrderRequests] });
  }

  renderOrderRequests() {
    return this.state.orderRequests.map((orderRequest, i) => {
      return (
        <OrderRequest
          key={orderRequest.key}
          index={i}
          organizationSlug={this.props.organizationSlug}
          orderRequest={orderRequest}
          onRemove={this.handleRemove}
          deletable={this.state.orderRequests.length > 1}
          addDependentPositions={this.addDependentPositions}
        />
      );
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '10%', paddingRight: 8 }}>Anzahl</div>
        <div style={{ width: '50%', paddingRight: 8 }}>Produkt</div>
        <div style={{ width: '20%', paddingRight: 8 }}>Vorschlag Lieferant</div>
        <div style={{ width: '15%' }}>Anlieferung</div>
        <div style={{ flexGrow: 1 }} />
      </div>
    );
  }

  render() {
    return (
      <div>

        {this.renderHeader()}
        {this.renderOrderRequests()}
        <p>
          {/* eslint-disable-next-line jsx-a11y/href-no-hash */}
          <a href="#" onClick={this.handleAddClick} className="link-with-icon">
            <Icon name="plus" />
            Zeile hinzufügen
          </a>
        </p>

        <Tooltip
          style={{ width: '300px', textAlign: 'left' }}
          title="Ein abhängiges Produkt wird in der Menge hinzugefügt, wie es in der Abhängigkeit
          definiert ist.
          Die Menge des abhängigen Produkts wird mit der Menge des Elternprodukts multipliziert.
          Bei verketteten abhängigen Produkten werden auch deren Mengenangaben multipliziert.
          Nachträgliche Änderungen an der Anzahl des Elternprodukts werden bei den abhängigeen
          Produkten nicht berücksichtigt.
          Bei Paketprodukten (P) werden
          nur die abhängigen Produkte hinzugefügt, nicht das Paketprodukt selbst."
        >
          <div className="form-check mb-3">
            <input
              type="checkbox"
              checked={this.state.addDependencies}
              onChange={() => this.handleToggleAddDependentPositions()}
              id="addDependencies"
              className="form-check-input" />
            <label htmlFor="addDependencies" className="form-check-label">
              Abhängige Produkte hinzufügen
            </label>
          </div>
        </Tooltip>
      </div>
    );
  }
}

NewOrderRequests.propTypes = {
  orderRequests: PropTypes.array,
  organizationSlug: PropTypes.string.isRequired,
};

export default NewOrderRequests;
