import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

class PdfDownloadButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdf_attachment: props.pdf_attachment,
    };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'PdfAttachmentChangesChannel',
      pdf_attachment_id: this.state.pdf_attachment.id,
    }, ({ pdf_attachment }) => {
      this.setState({
        pdf_attachment,
      });
    });
  }

  componentWillUnmount() {
    if (this.channel) unsubscribe(this.channel);
  }

  render() {
    if (this.state.pdf_attachment.status === 'stored') {
      return (
        <a href={this.state.pdf_attachment.preview_link} className="btn btn-primary" target="blank">
          <Icon name="file-pdf" /> PDF laden
        </a>
      );
    }

    return (
      <button className="btn btn-secondary btn-loading btn-disabled" disabled>
        wird erzeugt
      </button>
    );
  }
}

PdfDownloadButton.propTypes = {
  pdf_attachment: PropTypes.object.isRequired,
};

export default PdfDownloadButton;
