/* eslint-disable no-multi-spaces */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getAsync } from '../../helpers/rails_helper';
import { defaultStyles, smallStyles } from '../../styles/select_styles';

function EnumSelect(props) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(props.value);
  const [selectValue, setSelectValue] = useState(null);

  useEffect(() => {
    setSelectValue(options.filter((o) => o.value === value)[0] || null);
  }, [value, options]);

  const loadOptions = () => {
    getAsync(`/enums/${props.model}/${props.name}.json`)
      .then((result) => {
        setOptions(result);
      });
  };

  useEffect(() => {
    loadOptions();
  }, [props.model]);

  const handleChange = (v) => {
    setValue(v);
    if (props.onChange) props.onChange(v);
  };

  return (
    <Select
      name={props.name}
      isClearable={props.isClearable}
      openMenuOnFocus
      value={selectValue || undefined}
      isDisabled={props.disabled}
      onChange={handleChange}
      options={options}
      placeholder={props.placeholder}
      loadingPlaceholder="Laden..."
      noOptionsMessage={() => 'Keine Werte gefunden'}
      backspaceRemovesValue={false}
      blurInputOnSelect
      menuPlacement="auto"
      styles={props.size === 'small' ? smallStyles : defaultStyles}
      defaultOptions
    />
  );
}

EnumSelect.defaultProps = {
  isClearable: false,
  placeholder: 'Bitte auswählen',
  disabled: false,
  value: null,
  initialValue: '',
  size: 'default',
};

EnumSelect.propTypes = {
  model: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

export default EnumSelect;
