import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';
import BaseCondition from '../BaseCondition';

import CraftSelector from '../../../Crafts/CraftSelector';

class CraftCondition extends React.Component {

  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleValueChange = (craft) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      craft,
      value: craft,
      integer_operand_1: craft.id,
    }));
  };

  renderOperand1() {
    return (<div style={{ flexBasis: 200, marginLeft: 8 }}>
      <CraftSelector
        name="craft"
        size="small"
        value={this.props.condition.craft}
        organizationSlug={this.props.organizationSlug}
        onChange={this.handleValueChange}
      />
    </div>);
  }

  renderOperator() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperator()}
        {this.renderOperand1()}
      </BaseCondition>
    );
  }
}

CraftCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  selectedValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  value: PropTypes.object,
};

export default CraftCondition;
