import React from 'react';

import PropTypes from 'prop-types';

import OperatorSelector from '../../OperatorSelector';

import BaseCondition from '../BaseCondition';
import EnumSelect from '../../../shared/EnumSelect';

class LegalStatusCondition extends React.Component {
  handleChangeOperator = ({ value }) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      operator: value,
    }));
  };

  handleValueChange = (enumValue) => {
    this.props.onChange(Object.assign({}, this.props.condition, {
      enumValue,
      integer_operand_1: enumValue.value,
    }));
  };

  renderOperand1() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <EnumSelect model="InstallationCompany" name="legal_status"
                    onChange={this.handleValueChange} size="small"
                    value={this.props.condition.value} />
      </div>
    );
  }

  renderOperator() {
    return (
      <div style={{ flexBasis: 200, marginLeft: 8 }}>
        <OperatorSelector
          operators={this.props.condition.filter_condition_type.operators}
          onChange={this.handleChangeOperator}
          value={this.props.condition.operator}
        />
      </div>
    );
  }

  render() {
    return (
      <BaseCondition {...this.props}>
        {this.renderOperator()}
        {this.renderOperand1()}
      </BaseCondition>
    );
  }
}

LegalStatusCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
};

export default LegalStatusCondition;
