import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';

import Icon from '../shared/Icon';

import AreaMap, { styles } from './AreaMap';
import ProductSelector from '../Products/ProductSelector';

class AreaEditor extends Component {


  constructor(props) {
    super(props);

    this.state = {
      areas: [],
      isAreaProductDialogOpen: false,
    };

    this.modalRefs = {};
  }

  getModalRef = (modalId) => {
    if (!this.modalRefs[modalId]) {
      this.modalRefs[modalId] = createRef();
    }
    return this.modalRefs[modalId];
  };

  handleAreaAdded = (newArea) => {
    this.setState(prevState => ({
      areas: [...prevState.areas, newArea],
    }));
  };

  handleAreaRemoved = (area) => {
    if (area.id) {
      this.setState(prevState => ({
        areas: prevState.areas.map(a => ((a !== area) ? a : ({
          ...a,
          deleted: true,
        }))),
      }));
    } else {
      this.setState(prevState => ({
        areas: prevState.areas.filter(a => a !== area),
      }));
    }

    area.mapPolygon.setMap(null);
  };

  handleAreaChanged = (area) => {
    this.setState(prevState => ({
      areas: prevState.areas.map(a => ((a === area) ? area : a)),
    }));
  };

  handleMouseOver = (polygon) => {
    polygon.setOptions({
      fillColor: styles.POLYGON_FILL_COLOR_HOVER,
      fillOpacity: styles.POLYGON_FILL_OPACITY_HOVER,
    });
  };

  handleMouseOut = (polygon) => {
    polygon.setOptions({
      fillColor: styles.POLYGON_FILL_COLOR,
      fillOpacity: styles.POLYGON_FILL_OPACITY,
    });
  };

  handleDialogOpen = (area_id) => {
    this.setState({ isAreaProductDialogOpen: area_id }, () => {
      document.addEventListener('mousedown', this.handleDialogClickOutside);
    });
  };

  handleDialogClose = () => {
    this.setState({ isAreaProductDialogOpen: false }, () => {
      document.removeEventListener('mousedown', this.handleDialogClickOutside);
    });
  };

  handleDialogClickOutside = (event) => {
    const currentRef = this.modalRefs[this.state.isAreaProductDialogOpen];
    if (currentRef && currentRef.current && !currentRef.current.contains(event.target)) {
      this.handleDialogClose();
    }
  };

  handleProductOptionChange = (checked, area_id, product_id) => {
    this.setState(prevState => ({
      areas: prevState.areas.map(a => ((a.id === area_id) ? ({
        ...a,
        product_id: checked ? product_id : null,
      }) : a)),
    }));
  };

  renderInputs() {
    return this.state.areas.map((area) => {
      const points = area.mapPolygon.getPath().getArray();

      const values = [...points, points[0]].map((latLng) => {
        return `${latLng.lng()} ${latLng.lat()}`;
      }).join(', ');

      return (
        <Fragment key={area.id}>
          {area.persisted ? (
            <input
              type="hidden"
              name={`${this.props.areas_field}[${area.id}][id]`}
              value={area.id}
            />
          ) : ''}
          {area.deleted ? (
            <input
              type="hidden"
              name={`${this.props.areas_field}[${area.id}][_destroy]`}
              value="true"
            />
          ) : (
            <Fragment>
              <input
                type="hidden"
                name={`${this.props.areas_field}[${area.id}][polygon]`}
                value={`POLYGON((${values}))`}
              />
              {area.product_id ? (
                <input
                  type="hidden"
                  name={`${this.props.areas_field}[${area.id}][area_product_attributes][product_id]`}
                  value={area.product_id || ''}
                />
              ) : area.area_product_id && (
                <>
                  <input
                    type="hidden"
                    name={`${this.props.areas_field}[${area.id}][area_product_attributes][id]`}
                    value={area.area_product_id || ''}
                  />
                  <input
                    type="hidden"
                    name={`${this.props.areas_field}[${area.id}][area_product_attributes][_destroy]`}
                    value="1"
                  />
                </>
              )}
            </Fragment>
          )
          }
        </Fragment>
      );
    });
  }

  renderDialog(area_id) {
    return (
      <div className={`modal ${this.state.isAreaProductDialogOpen === area_id ? 'd-block' : ''}`} tabIndex="-1">
        <div className="modal-dialog modal-lg" ref={this.getModalRef(area_id)}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <Icon name="car" title="Anfahrtskosten" className={'me-2'} />
                Beauftragungsmehrkosten auswählen
              </h5>
              <button type="button" className="btn-close" onClick={this.handleDialogClose} aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className="alert alert-info">
                Manche Aktionsgebiete erfordern Beauftragungsmehrkosten für die Anfahrt.<br />
                Diese Mehrkosten müssen einerseits hier dem Aktionsgebiet zugewiesen werden.<br />
                Zum anderen muss der Preis für die Dienstleistung im Preiskatalog des Fachpartners hinterlegt werden.<br />
                Suche hier nach "Mehrkosten".
              </div>
              <ProductSelector
                value={this.state.areas.find(a => a.id === area_id)?.product_id}
                onChange={(product_id) => { this.handleProductOptionChange(true, area_id, product_id); }}
                organizationSlug={this.props.organization_slug}
                productType="service"
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={this.handleDialogClose}>Okay</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-3">
          <h4>{this.props.title}</h4>
          <p>
            {this.props.desc}
          </p>
          <ul className="list-group mb-3">
            {this.state.areas.filter(a => !a.deleted).map((area, i) => {
              return (
                <li
                  key={area.id} className="list-group-item area-list-item"
                  onMouseOver={() => this.handleMouseOver(area.mapPolygon)}
                  onMouseOut={() => this.handleMouseOut(area.mapPolygon)}>
                  <span className="float-end">
                    <button
                      className="btn btn-compact btn-link" onClick={(e) => {
                        e.preventDefault();
                        this.handleDialogOpen(area.id);
                      }}>
                      <Icon name="car" title="Anfahrtskosten" style={{ position: 'relative' }}>
                        {area.product_id && (<span
                          className="position-absolute top-0 start-100 translate-middle p-1 bg-primary border border-light rounded-circle"/>)}
                      </Icon>
                    </button>
                    <button
                      className="btn btn-compact btn-link" onClick={(e) => {
                        e.preventDefault();
                        this.handleAreaRemoved(area);
                      }}>
                      <Icon name="trash" title="Gebiet entfernen"/>
                    </button>
                  </span>
                  <strong>Gebiet {i + 1}</strong>
                  {this.renderDialog(area.id)}
                </li>
              );
            })}
          </ul>
          {this.renderInputs()}
          <button className="btn btn-primary" type="submit" disabled={this.state.areas.length === 0}>
            Speichern
          </button>
          <a href={this.props.cancel_link} className="btn btn-link">Abbrechen</a>
        </div>
        <div className="col-sm-9">
          <AreaMap
            editable
            initial_areas={this.props.initial_areas}
            postal_addresses={this.props.postal_addresses}
            areaChanged={this.handleAreaChanged}
            areaAdded={this.handleAreaAdded}
            areaRemoved={this.handleAreaRemoved}
          />
        </div>
      </div>
    );
  }
}

AreaEditor.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  cancel_link: PropTypes.string.isRequired,
  areas_field: PropTypes.string.isRequired,
  initial_areas: PropTypes.array,
  postal_addresses: PropTypes.array,
  organization_slug: PropTypes.string,
};

export default AreaEditor;
