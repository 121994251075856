import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => {
  const {
    name,
    title,
    fixedWidth,
    spin,
    light,
    brands,
    regular,
    pulse,
    className = '',
    color,
    visible,
    children,
  } = props;

  if (!visible) return null;

  let faStyle = 'fas';

  if (light) faStyle = 'fal';
  if (brands) faStyle = 'fab';
  if (regular) faStyle = 'far';

  const classNames = [className, faStyle, `fa-${name}`];

  if (fixedWidth) classNames.push('fa-fw');
  if (spin) classNames.push('fa-spin');
  if (pulse) classNames.push('fa-pulse');

  const style = props.style || {};

  if (color) style.color = color;

  return <i className={classNames.join(' ')} style={style} title={title}>{children}</i>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  spin: PropTypes.bool,
  pulse: PropTypes.bool,
  light: PropTypes.bool,
  brands: PropTypes.bool,
  regular: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  visible: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node,
};

Icon.defaultProps = {
  visible: true,
};

export default Icon;
